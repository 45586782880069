import React from "react";
import Title from "../components/TitleComponent";
import Employee from "../components/EmployeeComponent";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

export default function TeamPage() {

  const descNico = "Sunt psiholog clinician specialist si supervizor in psihologie clinica cu experienta in ceea ce priveste evaluarea si interventia in tulburarile de spectru autist, specialist acreditat de Colegiul Psihologilor din Romania in a oferi servicii de specialitate persoanelor diagnosticate cu autism (primul cabinet psihologic din Sibiu care poate deconta aceste servicii prin CAS). Sunt pasionata de acest domeniu si recunoscatoare pentru contextele de invatare (conferinte, cursuri si workshopuri de logopedie, abordarea traumei si pierderii in terapia copilului, interventie PTSD si depresie, psiho-oncologie) si oportunitatile profesionale de pana acum. Inspirata de prezenta profesionala si umana a unor colege, imi doresc ca impreuna sa putem fi de folos intr-un mod coerent si corect, sa fim un punct de sprijin pentru cei care au nevoie de sustinere psihologica."

  const descIoana = "Sunt psiholog clinician atestat de Colegiul Psihologilor din Romania si membru fondator al Asociatiei PsiNapsis. Activez in domeniu de peste 10 ani,  timp in care am lucrat atat cu copii, cat si cu adulti. Printre competentele mele se numara: evaluare, creare de programe de interventie personalizata, terapie individuala pentru copiii cu dizabilitati, consiliere copii si adulti. Aspectele care imi definesc activitatea profesionala sunt blandetea, capacitatea de analiza si observatie, abordarea individualizata si cuprinzatoare a dificultatilor cu care se confrunta clientii."

  const descRamo = "Sunt psiholog acreditat de Colegiul Psihologilor si membru al Asociaţiei PsiNapsis. Lucrez cu copiii de peste 15 ani, acoperind atat aria consilierii cat si a logopediei. Detin competente in evaluarea nevoilor individuale ale copiilor iar obiectivele mele sunt centrate pe fiecare copil in parte. Colaborarea cu familia copilului este un aspect esential al abordarii mele. Impreuna cu aceasta, incercam sa identificam nevoile copilului si sa gasim cele mai bune solutii pentru a le satisface. Ofer servicii de consiliere si logopedie intr-un mod empatic si atent, creand un spatiu sigur si prietenos asa incat copiii sa se dezvolte intr-un ritm propriu."

  const descElla = "Am acumulat pe parcursul anilor o experienta bogata in ceea ce priveste interactiunea cu copiii si familiile lor, in contextul desfasurarii unor activitati educationale. In cadrul Asociației PsiNapsis voi ajuta la conturarea unei imagini reprezentative in mediul online, la gandirea si implementarea de proiecte cat mai valoroase, bazandu-ma si pe competentele mele tehnice."

  return (
    <>
      <Helmet>
        <title>Echipa</title>
        <meta
          name="description"
          content="Echipa Asociatiei PsiNapsis este formata din psihologi clinicieni, logopezi si psihologi psihopedagogie speciala: Ilies Nicoleta, Stroe Ioana, Calinescu Diana, Nagara Ramona."
        />
        <link rel="canonical" href="https://www.psinapsis.ro/team" />
      </Helmet>
      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 pb-3 min-xl-screen-size">

          <Title title="Echipa noastra" />

          <Employee
            photo="/images/Nicoleta_IliesR.jpg"
            alt="Ilies Nicoleta - Presedinte Asociatia PsiNapsis, Psiholog clinician"
            name="Nicoleta Ilies - Presedinte Asociatia PsiNapsis, Psiholog clinician"
            shortDescLength="377"
            description={descNico}
          />

          <Employee
            photo="/images/IoanaR.jpg"
            alt="Stroe Ioana - Vicepresedinte Asociatia PsiNapsis, Psiholog clinician"
            name="Ioana Stroe - Vicepresedinte Asociatia PsiNapsis, Psiholog clinician"
            shortDescLength="206"
            description={descIoana}
          />

          <Employee
            photo="/images/Ramona.jpg"
            alt="Nagara Ramona - Logoped si psiholog, Asociatia PsiNapsis"
            name="Ramona Nagara - Logoped și psiholog"
            shortDescLength="297"
            description={descRamo}
          />
          
          <Employee
            photo="/images/Ella.jpg"
            alt="Cojocariu Mihaela - Web developer, Asociatia PsiNapsis"
            name="Mihaela Cojocariu - Web Developer voluntar"
            shortDescLength="170"
            description={descElla}
          />
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
