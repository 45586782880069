import React from "react";
import Title from "../../components/TitleComponent";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Details from "../../components/DetailsComponent";
import EventWImage from "../../components/EventWImageComponent";
import { Image } from "react-bootstrap";

export default function WorkshopEvent() {

  const intrebari = [
    "De ce flutura mainile?",
    "De ce se invarte in jurul propriei axe?",
    "De ce isi pune mana la urechi?",
    "De ce merge pe varfuri?",
    "De ce refuza anumite texturi?",
  ];

  return (
    <>

    <Helmet>
        <title>Workshop - Integrare Senzoriala</title>
        <meta
          name="description"
          content="Workshop dedicat specialistilor din domeniu care vor sa isi aprofundeze cunostintele cu privire la terapia de integrare senzoriala, dar si parintilor si cadrelor didactice care vor sa inteleaga ce se afla dincolo de toate aceste comportamente. "
        />
        <link rel="canonical" href="https://www.psinapsis.ro/events/workshopevent" />
      </Helmet>

    <Row className="p-0 m-0">
      <Col className="d-none d-xl-block"></Col>
      <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">

        <Title title="Workshop - Integrare Senzoriala" />

        <Details normalText="Nevoile senzoriale ghideaza de cele mai multe ori comportamentul pe care il manifesta copilul diagnosticat cu o tulburare de neurodezvoltare (Tulburare de spectru autist, ADHD etc.). Cautarea versus evitarea stimulilor reprezinta deseori un impediment in evolutia si castigarea de noi achizitii." />
        <Details normalText="Copiii cu dificultati de integrare senzoriala interpreteaza, de obicei, distorsionat imaginile, sunetele si senzatiile de atingere sau de miscare, pot fi deranjati de luminile intense sau sunetele puternice si pot manifesta disconfort daca sunt atinsi sau miscati pe neasteptate." />

        <EventWImage
            photoSource="/images/workshop.jpg"
            alt="Evaluarea dislexiei si disortografiei, Asociatia PsiNapsis, Sibiu"
            objectives={intrebari}
          />

        <Image
            className="photoEvent d-block d-md-none mx-auto mb-4"
            src="/images/workshop.jpg"
            alt="Evaluarea dislexiei si disortografiei, Asociatia PsiNapsis, Sibiu"
          />

        <Details normalText=" Prin activitatea asociatiei promovam demersuri de informare, impartasire a cunostintelor, sustinere, coeziune si crearea de legaturi intre diversi specialisti cu experienta. " />

        <Details normalText="In acest context, la sediul Asociatiei noastre va avea loc un workshop dedicat specialistilor din domeniu care vor sa isi aprofundeze cunostintele cu privire la terapia de integrare senzoriala, dar si parintilor si cadrelor didactice care vor sa inteleaga ce se afla dincolo de toate aceste comportamente." />
        <Details normalText="Participarea va fi gratuita, insa in vederea mentinerii calitatii, numarul de participanti va fi limitat." />



        <div>
            <span className="ms-5 me-2">
              <Image src="/icons/calendar_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">5 octombrie 2024</span>
          </div>

          <div>
            <span className="ms-5 me-2">
              <Image src="/icons/hour_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">09:00 - 12:30</span>
          </div>

          <div>
            <span className="ms-5 me-2">
              <Image src="/icons/location_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">Asociația PsiNapsis</span>
          </div>

      </Col>
      <Col className="d-none d-xl-block"></Col>
    </Row>
    </>
  );
}
